import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConfigurationService} from 'src/app/core/config/configuration.service';
import {ItemPayload} from 'src/app/interfaces/general/responses/item-payload.interface';
import {IOrder} from 'src/app/interfaces/general/profile-definitions/order.interface';
import {ParamsEncoder} from 'src/app/core/config/params-encoder';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class OrdersService {
    constructor(
        private http: HttpClient,
        private config: ConfigurationService
    ) {}

    public getOrders(nextPageToken?: string, partner?: string): Observable<ItemPayload<IOrder>> {
        let params = new HttpParams({encoder: new ParamsEncoder()}).append('top', 10000);
        if (nextPageToken) {
            params = params.append('continuationToken', nextPageToken);
        }
        if (partner) {
            params = params.append('partner', partner);
        }
        return this.http.get<ItemPayload<IOrder>>(`${this.config.get('profileApiUrl')}/orders`, {params}).pipe(
            map((res) => {
                const data = [...res.data ?? []].sort((a, b) => {
                    const aDate = new Date(a.createdDateTime).getTime();
                    const bDate = new Date(b.createdDateTime).getTime();
                    return bDate - aDate;
                });
                return {...res, data};
            })
        );
    }

    public getOrderById(id: string): Observable<IOrder> {
        return this.http.get<IOrder>(
            `${this.config.get('profileApiUrl')}/orders/${id}`
        );
    }
}
